import { ACCOUNT_CONNECT_TYPE } from "./enum";

export const IPFS_GATEWAY = "https://lens.infura-ipfs.io/ipfs/";
export const IMGPROXY_URL = "https://media.lenster.xyz";
export const PROVIDER_ID_CONFIG: Record<ACCOUNT_CONNECT_TYPE, any> = {
  [ACCOUNT_CONNECT_TYPE.LENS]: "web3mq:lens.xyz",
  [ACCOUNT_CONNECT_TYPE.EMAIL]: "web3mq:email:SwapChat",
  [ACCOUNT_CONNECT_TYPE.PHONE]: "web3mq:sms:SwapChat",
  [ACCOUNT_CONNECT_TYPE.ENS]: "web3mq:ens:SwapChat",
  [ACCOUNT_CONNECT_TYPE.DOTBIT]: "web3mq:dotbit:SwapChat",
};


export const COVER = "800";
