import {ACCOUNT_CONNECT_TYPE} from "../../constant/enum";

export const pageConfig = {
    [ACCOUNT_CONNECT_TYPE.EMAIL]: {
        headerTitle: "Connect to your email",
        placeholder: "Input your email",
        inputType: "email",
        contentTitle:
            "We’ll send you a one-time verification code to comfirm your email.",
        stepTwoContentTitle: "Please enter 6-digit code sent to you email",
        successTitle: "Send success",
        successSubTitle: "Please check your email",
    },
    [ACCOUNT_CONNECT_TYPE.PHONE]: {
        headerTitle: "Connect to your phone number",
        placeholder: "Input your phone",
        inputType: "tel",
        contentTitle:
            "We’ll text you a one-time verification code to comfirm your number.",
        stepTwoContentTitle: "Please enter 6-digit code sent to you phone",
        successTitle: "Your phone is verified",
        successSubTitle:
            "Congratulation! Your phone number successfully links to your Web3MQ.",
    },
    [ACCOUNT_CONNECT_TYPE.LENS]: {
        headerTitle: "Bind lens account",
        placeholder: "Input a lens address",
        inputType: "text",
        contentTitle:
            "This is the address of the lens under your account, choose one to bind",
        stepTwoContentTitle: "",
        successTitle: "",
        successSubTitle: "",
    },
    [ACCOUNT_CONNECT_TYPE.ENS]: {
        headerTitle: "Bind ENS",
        placeholder: "Input your ens address",
        inputType: "text",
        contentTitle: "Please input your ens address",
        stepTwoContentTitle: "",
        successTitle: "Bind Success",
        successSubTitle: "ENS",
    },
    [ACCOUNT_CONNECT_TYPE.DOTBIT]: {
        headerTitle: "Bind Dot.Bit address",
        placeholder: "Input your Dot.Bit address",
        inputType: "text",
        contentTitle: "Please input your ens address",
        stepTwoContentTitle: "",
        successTitle: "Bind Success",
        successSubTitle: "Dot.Bit",
    },
};