import React, { useEffect, useState } from "react";

import ss from "./index.module.scss";
import {
  IonModal,
  useIonLoading,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../services/mobx/service";
import { RouteComponentProps } from "react-router";
import MemberProfileModal from "../../components/MemberProfileModal";
import ProfileEmptyPage from "../../components/ProfileEmptyPage";
import ProfileModal from "../../components/ProfileModal";
import Header from "../Header";
import HomePageSkeleton from "../../components/Skeletons/HomePageSkeleton/HomePageSkeleton";
import { PAGE_TYPE } from "../../constant/enum";
import useLogin from "../../hooks/useLogin";
import { useHistory } from "react-router-dom";
import Web3MQChat from "../../components/Web3MQChat";

const blackHeaderLogo = require("../../assert/svg/blackheaderLogo.svg").default;

interface MatchParams {
  address: string;
}

const Home: React.FC<RouteComponentProps<MatchParams>> = observer((props) => {
  const address = props.match.params.address;
  const store = useStore();
  const history = useHistory();
  const [openLoading, closeLoading] = useIonLoading();
  const { keys, init, loginWeb3MQ } = useLogin();
  const [isLensStyle, setIsLensStyle] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const initUserInfo = async (address: string) => {
    if (address) {
      setShowSkeleton(true);
      await init();
      await store.getUserInfo(address);
      setShowSkeleton(false);
    } else {
      history.push("/auth");
    }
  };
  useEffect(() => {
    initUserInfo(address).then();
  }, [address]);
  const initRender = async () => {
    if (!store.loginUserInfo) {
      if (keys) {
        await openLoading("Loading");
        await loginWeb3MQ();
        if (address) {
          await store.getUserInfo(address);
        }
        await closeLoading();
      }
    }
  };

  useEffect(() => {
    // 保证事件只挂载一次  避免重复render
    window.addEventListener("resize", () => {
      store.setIsMobile(window.innerWidth <= 600);
    });
    initRender();
  }, []);
  return (
    <div className={ss.profileContainer}>
      <Header isMobile={store.isMobile} isLensStyle={isLensStyle} />
      <div className={ss.profileContentBox}>
        <div className={ss.profileContent}>
          <div className={ss.backgroundHeader} />
          {showSkeleton ? (
            <div
              style={{
                maxWidth: "1000px",
                margin: "auto",
                height: "calc(100vh - 180px)",
              }}
            >
              <HomePageSkeleton />
            </div>
          ) : (
            <>
              {store.userInfo ? (
                <MemberProfileModal
                  isLensStyle={isLensStyle}
                  userInfo={store.userInfo}
                  isMobile={store.isMobile}
                />
              ) : (
                <ProfileEmptyPage />
              )}
            </>
          )}
          {store.showChat && store.client && <Web3MQChat />}
          <div className={ss.footer}>
            <div className={ss.footerBox}>
              <div className={ss.leftBox}>
                <img src={blackHeaderLogo} alt="" />
              </div>
              <div className={ss.rightBox}>
                <div className={ss.boxItem}>
                  <a target="_blank" href="https://docs.web3mq.com/">
                    Docs
                  </a>
                </div>
                <div className={ss.boxItem}>
                  <a target="_blank" href="https://www.web3messaging.online/">
                    Web3MQ
                  </a>
                </div>
                <div className={ss.boxItem}>
                  <a target="_blank" href="https://twitter.com/SwapChatNFT">
                    Twitter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <IonModal
        isOpen={store.showModal}
        //@ts-ignore
        cssClass={ss.modal}
        onDidDismiss={() => {
          store.setShowModal(false);
          store.setPageType(PAGE_TYPE.PROFILE);
        }}
      >
        <ProfileModal isLensStyle={isLensStyle} />
      </IonModal>
    </div>
  );
});
export default Home;
