import React, { useEffect } from "react";
import { MetaMaskIcon } from "../../icons";

import "./index.css";
import useLogin from "../../hooks/useLogin";
import { useIonLoading } from "@ionic/react";
import { useStore } from "../../services/mobx/service";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import headerLogo from "../../assert/svg/blackheaderLogo.svg";

const Login: React.FC = () => {
  const { keys, loginWeb3MQ } = useLogin();
  const history = useHistory();
  const [openLoading, closeLoading] = useIonLoading();
  const store = useStore();

  useEffect(() => {
    initRender().then();
  }, []);

  const initRender = async () => {
    if (keys) {
      await openLoading("Loading");
      await loginWeb3MQ();
      if (store.loginUserInfo && store.loginUserInfo.address) {
        history.push(`/home/${store.loginUserInfo.address}`);
      } else {
        console.log("不正常2");
        //@ts-ignore
        history.push(`/home/${window.ethereum.selectedAddress}`);
      }
      await closeLoading();
    }
  };

  const handleLogin = async () => {
    await openLoading("Loading");
    await loginWeb3MQ();
    if (store.loginUserInfo) {
      history.push(`/home/${store.loginUserInfo.address}`);
    } else {
      //@ts-ignore
      history.push(`/home/${window.ethereum.selectedAddress}`);
    }
    await closeLoading();
  };

  return (
    <div className="login_container">
      <div
        className="logoBox"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ height: "60px", width: "auto" }}
          src={headerLogo}
          alt=""
        />
      </div>
      <div className="step_box" style={{ marginTop: "50px" }}>
        <div className="up_text">Welcome to web3connect</div>
        <div className="down_text">Powered by web3mq</div>
        <div className="step_text">Connect Wallet</div>
      </div>
      <div className="button_box">
        <button onClick={handleLogin} className="sign_btn">
          <MetaMaskIcon />
          Connect Web3MQ
        </button>
      </div>
    </div>
  );
};

export default observer(Login);
