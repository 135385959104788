import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import headerLogo from "../../assert/svg/headerLogo.svg";
import lensLogoIcon from "../../assert/svg/lensLogoIcon.svg";

import ss from "./index.module.scss";
import { useStore } from "../../services/mobx/service";
import {getShortAddressByAddress, getUserAvatar} from "../../constant/utils";
import { PAGE_TYPE } from "../../constant/enum";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import useLogin from "../../hooks/useLogin";
import { useIonLoading } from "@ionic/react";
import userIcon from "../../assert/svg/user.svg";

interface IAppProps {
  isMobile: boolean;
  isLensStyle?: boolean;
}

const Header: React.FC<IAppProps> = (props) => {
  const store = useStore();
  const history = useHistory();
  const { logout, loginWeb3MQ } = useLogin();
  const [openLoading, closeLoading] = useIonLoading();
  const { isMobile, isLensStyle = false } = props;
  const handleLoginOut = () => {
    logout();
    store.logout();
  };
  const handleSubscribers = async () => {
    store.setShowModal(true);
    store.setPageType(PAGE_TYPE.SUBSCRIBERS);
    await openLoading("Loading");
    await store.getContacts(store.loginUserInfo?.web3mqInfo?.userid || '');
    await store.getFollowers(store.loginUserInfo?.web3mqInfo?.userid || '');
    await closeLoading();
  };

  const handleProfile = async () => {
    if (store.loginUserInfo && store.loginUserInfo.address) {
      history.push(`/home/${store.loginUserInfo.address}`);
    }
  };

  const handlePushMessage = () => {
    store.setShowModal(true);
    store.setPageType(PAGE_TYPE.BULK_MESSAGE);
  };
  const handleSetting = async () => {
    // await store.getUserSetting();
    store.setShowModal(true);
    store.setPageType(PAGE_TYPE.SETTING);
  };

  const menus = useMemo(
    () => [
      {
        title: "Subscribers",
        fn: handleSubscribers,
      },
      {
        title: "Settings",
        fn: handleSetting,
      },
      {
        title: "Bulk Message",
        fn: handlePushMessage,
      },
      {
        title: "Logout",
        fn: handleLoginOut,
      },
    ],
    [handleLoginOut, handleSubscribers]
  );

  const handleLogin = async () => {
    await loginWeb3MQ();
  };

  const RenderLoginBar = useCallback(() => {
    if (store.loginUserInfo) {
      return (
        <div className={ss.headRight}>
          <div className={ss.userInfo}>
            {!isMobile && (
              <div
                className={cx(ss.name, {
                  [ss.lensName]: isLensStyle,
                })}
              >
                <div className={ss.successConnectedBox}></div>
                {`Connected to ${getShortAddressByAddress(
                  store.loginUserInfo.address
                )}`}
              </div>
            )}
            <div className={ss.avatar}>
              <img src={store.loginUserInfo.defaultUserAvatar} alt="" onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = store.loginUserInfo?.address ?  getUserAvatar(store.loginUserInfo.address) : userIcon;
              }} />
            </div>
          </div>
          <div className={ss.popover}>
            <ul>
              {isMobile && (
                <li className={ss.popoverItem}>
                  <div className={ss.successConnectedBox}></div>
                  {`Connected to ${getShortAddressByAddress(
                    store.loginUserInfo.address
                  )}`}
                </li>
              )}
              {store.loginUserInfo && (
                <li className={ss.popoverItem} onClick={handleProfile}>
                  Profile
                </li>
              )}
              {menus.map((item) => {
                return (
                  <li
                    className={ss.popoverItem}
                    key={item.title}
                    onClick={item.fn}
                  >
                    {item.title}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    }
    return (
      <div
        className={cx(ss.connectBtn, {
          [ss.lensConnectBtn]: isLensStyle,
        })}
        onClick={handleLogin}
      >
        Login
      </div>
    );
  }, [store.loginUserInfo, isMobile]);
  return (
    <>
      <div
        className={cx(ss.header, {
          [ss.mobileHeader]: isMobile,
          [ss.lensHeader]: isLensStyle,
        })}
      >
        <div
          className={cx(ss.headerBox, {
            [ss.mobileHeaderBox]: isMobile,
          })}
        >
          <div className={ss.headLeft}>
            <img
              style={isLensStyle ? { height: "70px" } : {}}
              src={isLensStyle ? lensLogoIcon : headerLogo}
              alt=""
            />
          </div>
          <RenderLoginBar />
        </div>
      </div>
    </>
  );
};

export default observer(Header);
