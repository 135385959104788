import { action, makeAutoObservable, observable } from "mobx";
import React from "react";
import { PAGE_TYPE, WEB3_MQ_DID_TYPE} from "../../constant/enum";
import {
  Client,
  getPublicFollowerListRequest,
  getPublicFollowingListRequest,
  getUserPublicProfileRequest,
} from "web3-mq";
import {
  getDidsByRss3,
  getProfile,
  getProfileFromRss3,
} from "../../lens/api";
import { getUserAvatar } from "../../constant/utils";
import {PROVIDER_ID_CONFIG} from "../../constant";

type didValueType = Record<WEB3_MQ_DID_TYPE, any>;

type StatsType = {
  total_followers: number;
  total_following: number;
};

export type Web3MqUserInfoType = {
  userid: string;
  wallet_address: string;
  avatar_url?: string;
  nickname?: string;
  didValues?: didValueType;
  is_my_following?: boolean;
  stats?: StatsType;
};

export type UserInfoType = {
  defaultUserName: string;
  defaultUserAvatar: string;
  address: string;
  ens: string;
  bit: string;
  lens: string;
  web3mqInfo: Web3MqUserInfoType | null;
  lensInfo: any;
  ensInfo: any;
  bitInfo: any;
  csbInfo: any;
  permissions?: any;
};


export default class AppStore {
  @observable isMobile: boolean = window.innerWidth <= 600;
  @observable userInfo: UserInfoType | null = null;
  @observable loginUserInfo: UserInfoType | null = null;
  @observable pageType: PAGE_TYPE = PAGE_TYPE.PROFILE;
  @observable contacts: any[] = [];
  @observable followers: any[] = [];
  @observable showModal: boolean = false;
  @observable isFollowedByMe: boolean = false;
  @observable client: Client | null = null;
  @observable showChat: boolean = false

  @action setShowChat(data: boolean) {
    this.showChat = data;
  }

  @action setShowModal(data: boolean) {
    this.showModal = data;
  }

  @action setIsMobile(data: boolean) {
    this.isMobile = data;
  }

  @action setPageType(data: PAGE_TYPE) {
    this.pageType = data;
  }

  @action
  async setClient(data: Client) {
    this.client = data;
    this.client.on("contact.getList", this.handleEvent);
    this.client.on("message.delivered", this.handleEvent);
    this.client.on("message.getList", this.handleEvent);
    // setLoginUserInfo
    const myProfile = await data.user.getMyProfile();
    if (myProfile) {
      let loginUserInfo: UserInfoType = await this.getUserProfileBase(myProfile.wallet_address)
      loginUserInfo.web3mqInfo = myProfile
      // this.loginUserInfo.web3MqInfo = myProfile;
      const permissions = await data.user
        .getUserPermissions()
        .catch((e) => console.log(e));
      if (permissions && permissions.permissions) {
        loginUserInfo.permissions = permissions.permissions;
      }
      if (loginUserInfo.ens) {
        await this.client.user.userBindDid({
          provider_id: PROVIDER_ID_CONFIG.ens,
          did_type: WEB3_MQ_DID_TYPE.ENS,
          did_value: loginUserInfo.ens,
        })
      }

      if (loginUserInfo.lens) {
        await this.client.user.userBindDid({
          provider_id: PROVIDER_ID_CONFIG.lens,
          did_type: WEB3_MQ_DID_TYPE.LENS,
          did_value: loginUserInfo.lens,
        })

      }
      if (loginUserInfo.bit) {
        await this.client.user.userBindDid({
          provider_id: PROVIDER_ID_CONFIG.dotbit,
          did_type: WEB3_MQ_DID_TYPE.DOTBIT,
          did_value: loginUserInfo.bit,
        })
      }
      const dids = await this.client.user.getUserBindDids();
      if (dids) {
        loginUserInfo.web3mqInfo.didValues = await this.setUserDid(dids);
      }
      this.loginUserInfo = loginUserInfo
    } else {
      this.loginUserInfo = null
    }
  }

  @action
  async setLoginUserInfoDids(data: any) {
    if (this.loginUserInfo && this.loginUserInfo.web3mqInfo) {
      this.loginUserInfo.web3mqInfo.didValues = data;
    }
  }

  handleEvent(event: any) {
    console.log(event, "event");
  }

  @action
  async getUserInfo(address: string) {
    try {
      let userInfo = await this.getUserProfileBase(address)
      if (this.loginUserInfo && this.loginUserInfo.address === address) {
        this.userInfo = this.loginUserInfo
        return true
      }
      const web3MqInfo = await getUserPublicProfileRequest({
        did_type: "eth",
        did_value: userInfo.address,
        timestamp: Date.now(),
        my_userid: this.loginUserInfo ? this.loginUserInfo.web3mqInfo?.userid || '' : "",
      }).catch((e) => {
        console.log(e);
      });
      if (web3MqInfo && web3MqInfo.data) {

        userInfo.web3mqInfo = web3MqInfo.data
        if (
            userInfo.web3mqInfo &&
            web3MqInfo.data.bind_did_list &&
            web3MqInfo.data.bind_did_list.length > 0
        ) {
          userInfo.web3mqInfo.didValues = await this.setUserDid(web3MqInfo.data.bind_did_list);
        }


        this.isFollowedByMe = web3MqInfo.data.is_my_following


      }else {
        userInfo.web3mqInfo = null
        this.isFollowedByMe = false
      }
      this.userInfo = userInfo
    } catch (e) {
      console.log(e, "e");
    }
  }
  @action
  async getUserProfileBase(address: string) {
    const commonProfile = await getProfileFromRss3(address);
    let userInfo: UserInfoType = {
      ...commonProfile,
      defaultUserAvatar: getUserAvatar(commonProfile.address),
      web3MqInfo: null,
      lensInfo: null,
      ensInfo: null,
      bitInfo: null,
      csbInfo: null,
    };
    // all did on instance
    const profiles = await getDidsByRss3(userInfo.address);
    if (profiles) {
      if (profiles.avatar) {
        userInfo.defaultUserAvatar = profiles.avatar;
      }
      if (profiles.lensInfo) {
        userInfo.lensInfo = profiles.lensInfo;
        if (userInfo.lensInfo.name) {
          userInfo.lens = userInfo.lensInfo.name
        }
      }
      if (profiles.ensInfo) {
        userInfo.ensInfo = profiles.ensInfo;
        if (userInfo.ensInfo.name) {
          userInfo.ens = userInfo.ensInfo.name
        }
      }
      if (profiles.csbInfo) {
        userInfo.csbInfo = profiles.csbInfo;
      }
    }
    return userInfo;
  }

  @action
  async setUserDid(dids: any[]) {
    let res: didValueType = {
      [WEB3_MQ_DID_TYPE.PHONE]: "",
      [WEB3_MQ_DID_TYPE.EMAIL]: "",
      [WEB3_MQ_DID_TYPE.LENS]: null,
      [WEB3_MQ_DID_TYPE.ENS]: "",
      [WEB3_MQ_DID_TYPE.DOTBIT]: "",
    };

    if (dids && dids.length > 0) {
      dids.forEach((item: any) => {
        if (item.did_value) {
          if (item.did_type === WEB3_MQ_DID_TYPE.EMAIL) {
            res[WEB3_MQ_DID_TYPE.EMAIL] = item.did_value;
          }
          if (item.did_type === WEB3_MQ_DID_TYPE.PHONE) {
            res[WEB3_MQ_DID_TYPE.PHONE] = item.did_value;
          }
          if (item.did_type === WEB3_MQ_DID_TYPE.ENS) {
            res[WEB3_MQ_DID_TYPE.ENS] = item.did_value;
          }
          if (item.did_type === WEB3_MQ_DID_TYPE.DOTBIT) {
            res[WEB3_MQ_DID_TYPE.DOTBIT] = item.did_value;
          }
        }
      });
      const lensInfo = dids.find(
        (item: any) => item.did_type === WEB3_MQ_DID_TYPE.LENS
      );
      if (lensInfo) {
        // 有lens信息
        const lensHandle = lensInfo.did_value;
        res[WEB3_MQ_DID_TYPE.LENS] = {
          handle: lensHandle,
        };
        const lensProfile = await getProfile(lensHandle);
        if (lensProfile) {
          res[WEB3_MQ_DID_TYPE.LENS] = lensProfile;
        }
      }
    }
    return res;
  }

  @action
  async getContacts(userid: string) {
    if (this.client) {
      const timestamp = Date.now();
      const res = await getPublicFollowingListRequest({
        page: 1,
        size: 100,
        userid,
        timestamp,
      });
      if (res && res.data && res.data.user_list) {
        this.contacts = res.data.user_list;
      } else {
        this.contacts = [];
      }
    }
  }

  @action
  async getFollowers(userid: string) {
    try {
      if (this.client) {
        const timestamp = Date.now();
        const res = await getPublicFollowerListRequest({
          page: 1,
          size: 100,
          timestamp,
          userid,
        });
        if (res && res.data && res.data.user_list) {
          this.followers = res.data.user_list;
        } else {
          this.followers = [];
        }
      }
    } catch (e) {
      console.log(e, "follow - e");
    }
  }

  @action logout() {
    localStorage.clear();
    this.loginUserInfo = null;
    this.client = null;
  }

  @action isOwner() {
    return !!(
      this.loginUserInfo && this.loginUserInfo.address === this.userInfo?.address
    );
  }

  constructor() {
    makeAutoObservable(this); //even though this isn't required in some examples, this seems key line to making mobx work
  }
}

export const StoreContext = React.createContext(new AppStore());
export const StoreProvider = StoreContext.Provider;
export const useStore = () => React.useContext(StoreContext);
