import { getOneProfileQuery, getProfileQuery } from "./lensApiQueryConfig";
import { getShortAddressByAddress } from "../constant/utils";
import { RSS3_USER_DID_PROFILE_PLATFORM } from "../constant/enum";

export const getProfiles = async (address: string) => {
  try {
    const query = getProfileQuery;
    const variables = {
      request: {
        ownedBy: address,
      },
    };
    const result = await fetch("https://api.lens.dev/", {
      headers: {
        accept: "*/*",
        "accept-language": "zh-CN,zh;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
        "content-type": "application/json",
        "sec-ch-ua":
          '"Microsoft Edge";v="107", "Chromium";v="107", "Not=A?Brand";v="24"',
        "sec-ch-ua-mobile": "?0",
        "sec-ch-ua-platform": '"macOS"',
        "sec-fetch-dest": "empty",
        "sec-fetch-mode": "cors",
        "sec-fetch-site": "cross-site",
      },
      referrerPolicy: "strict-origin-when-cross-origin",
      body: JSON.stringify({
        operationName: "profiles",
        query,
        variables,
      }),
      method: "POST",
      mode: "cors",
      credentials: "omit",
    })
      .then((res) => {
        return res.json();
      })
      .catch((e) => {
        console.log(e);
      });
    return result.data.profiles;
  } catch (e) {
    return null;
  }
};

export const getProfile = async (handle: string) => {
  try {
    const query = getOneProfileQuery;
    const variables = {
      request: {
        handle,
      },
    };
    const operationName = "profile";
    const result = await fetch("https://api.lens.dev/", {
      headers: {
        accept: "*/*",
        "accept-language": "zh-CN,zh;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
        "content-type": "application/json",
        "sec-ch-ua":
          '"Microsoft Edge";v="107", "Chromium";v="107", "Not=A?Brand";v="24"',
        "sec-ch-ua-mobile": "?0",
        "sec-ch-ua-platform": '"macOS"',
        "sec-fetch-dest": "empty",
        "sec-fetch-mode": "cors",
        "sec-fetch-site": "cross-site",
      },
      referrerPolicy: "strict-origin-when-cross-origin",
      body: JSON.stringify({
        operationName,
        query,
        variables,
      }),
      method: "POST",
      mode: "cors",
      credentials: "omit",
    })
      .then((res) => {
        return res.json();
      })
      .catch((e) => {
        console.log(e);
      });
    return result.data.profile;
  } catch (e) {
    return null;
  }
};

export const rss3FeedNodes = async (address: string, skipId: string = "") => {
  const options = {
    method: "POST",
    headers: { accept: "application/json", "content-type": "application/json" },
    body: JSON.stringify({
      address: [address],
      limit: 100,
      refresh: false,
      include_poap: false,
      ignore_contract: false,
      count_only: false,
      query_status: false,
      cursor: skipId,
      tag: ["social"],
    }),
  };

  return await fetch("https://pregod.rss3.dev/v1/notes", options)
    .then((response) => response.json())
    .catch((err) => console.error(err));
};

export const getDidsByRss3 = async (user: string) => {
  const options = { method: "GET", headers: { accept: "application/json" } };
  const profiles = await fetch(
    `https://pregod.rss3.dev/v1/profiles/${user}`,
    options
  )
    .then((response) => response.json())
    .catch((err) => console.error(err));
  if (profiles && profiles.result && profiles.result.length > 0) {
    const { result } = profiles;
    let userInfo = {
      avatar: '',
      lensInfo: result.find(
        (item: any) => item.platform === RSS3_USER_DID_PROFILE_PLATFORM.lens
      ),
      ensInfo: result.find(
        (item: any) => item.platform === RSS3_USER_DID_PROFILE_PLATFORM.ens
      ),
      // bitInfo: null,
      csbInfo: result.find(
        (item: any) => item.platform === RSS3_USER_DID_PROFILE_PLATFORM.csb
      ),
    };
    const item = userInfo.ensInfo
      ? userInfo.ensInfo
      : userInfo.lensInfo
      ? userInfo.lensInfo
      : userInfo.csbInfo
      ? userInfo.csbInfo
      : null;
    if (item && item.profile_uri && item.profile_uri[0]) {
      userInfo.avatar = item.profile_uri[0]
    }
    return userInfo;
  }
  return null;
};
export const getProfileFromRss3 = async (user: string) => {
  const options = { method: "GET", headers: { accept: "application/json" } };
  let profile = await fetch(`https://pregod.rss3.dev/v1/ns/${user}`, options)
    .then((response) => response.json())
    .catch((err) => console.error(err));
  profile.defaultUserName = profile.ens
    ? profile.ens
    : profile.bit
    ? profile.bit
    : profile.lens
    ? profile.lens
    : profile.crossbell
    ? profile.crossbell
    : getShortAddressByAddress(profile.address || "");
  return profile;
};

export const getProfileAvatarFromScan = (profiles: any[]) => {};
