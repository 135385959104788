import React, { useCallback, useEffect, useState } from "react";
import ss from "./index.module.scss";
import { IonButton, IonModal, useIonLoading, useIonToast } from "@ionic/react";
import { UserInfoType, useStore } from "src/services/mobx/service";
import { observer } from "mobx-react-lite";
import {
  getPlatformByUrl,
  getShortAddressByAddress,
  getUserAvatar,
} from "../../constant/utils";
import {
  ACCOUNT_CONNECT_TYPE,
  PAGE_TYPE,
  Rss3Platforms,
  Rss3TypeMap,
  WEB3_MQ_DID_TYPE,
} from "../../constant/enum";
import cx from "classnames";
import copy from "copy-to-clipboard";
import useLogin from "../../hooks/useLogin";
import { rss3FeedNodes } from "../../lens/api";
import ConnectAccountModal from "../ConnectAccountModal";

const userIcon =
  "https://s3.us-west-2.amazonaws.com/videos.house.west2/SwapChat-static/svg/user.svg";
const sendMessageIcon =
  require("../../assert/svg/profileSendMessageIcon.svg").default;
const lensSendMessageIcon =
  require("../../assert/svg/lensProfileSendMessageIcon.svg").default;
const followIcon = require("../../assert/svg/followIcon.svg").default;
const unFollowIcon = require("../../assert/svg/unFollowIcon.svg").default;
const copyIcon = require("../../assert/svg/copyIcon.svg").default;
const lensCopyIcon = require("../../assert/svg/lensCopyIcon.svg").default;
const profileDotBitIcon = require("../../assert/img/bitLogoIcon.png").default;
const profileENSIcon =
  "https://s3.us-west-2.amazonaws.com/videos.house.west2/SwapChat-static/svg/profileENSIcon.svg";
const isBusinessUserIcon =
  require("../../assert/svg/isBusinessUser.svg").default;
const emailIcon = require("../../assert/svg/emailIcon.svg").default;
const phoneIcon = require("../../assert/svg/phoneIcon.svg").default;
const lensLogoIcon = require("../../assert/svg/lensLogoIcon.svg").default;
const connectMoreAccountIcon =
  require("../../assert/svg/connectMoreAccountIcon.svg").default;

interface IAppProps {
  userInfo: UserInfoType;
  isMobile: boolean;
  needName?: boolean;
  isLensStyle?: boolean;
}

const MemberProfileModal: React.FC<IAppProps> = observer((props) => {
  const store = useStore();
  const { loginWeb3MQ } = useLogin();
  const [present] = useIonToast();
  const [openLoading, closeLoading] = useIonLoading();
  const {
    userInfo,
    isMobile = false,
    needName = true,
    isLensStyle = false,
  } = props;
  const [segmentValue, setSegmentValue] = useState("1");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [lensPosts, setLensPosts] = useState<any[]>([]);
  const [pageType, setPageType] = useState<ACCOUNT_CONNECT_TYPE>(
    ACCOUNT_CONNECT_TYPE.PHONE
  );
  const [lastCursor, setLastCursor] = useState("");

  const getUserFeeds = async () => {
    if (userInfo && userInfo.address) {
      await openLoading("Loading...");
      const res = await rss3FeedNodes(userInfo.address, lastCursor);
      if (res && res.result) {
        setLensPosts([...lensPosts, ...res.result]);
        setLastCursor(res.cursor);
      }
      await closeLoading();
    }
  };

  useEffect(() => {
    const init = async () => {
      await getUserFeeds();
    };
    init();
  }, []);

  useEffect(() => {
    console.log(lensPosts, "lensPosts");
  }, [lensPosts]);

  // useIonViewWillEnter(() => {
  //   // 处理tab1白屏问题
  //   let errClassStr = "ion-page-invisible";
  //   let ele: any = document.querySelector(".member-profile-modal-page");
  //   if (ele && ele.className) {
  //     let str = ele.className;
  //     let index = str.indexOf(errClassStr);
  //     if (index > -1) {
  //       ele.className = str.replace(errClassStr, "");
  //     }
  //   }
  // });

  const RenderAccountsList = useCallback(() => {
    let phoneValue = store.isOwner() ? "Not Bind" : "Coming soon";
    let hasPhone = false;
    let emailValue = store.isOwner() ? "Not Bind" : "Coming soon";
    let hasEmail = false;
    if (userInfo.web3mqInfo?.didValues) {
      if (userInfo?.web3mqInfo?.didValues[WEB3_MQ_DID_TYPE.PHONE]) {
        phoneValue = getShortAddressByAddress(
          userInfo?.web3mqInfo?.didValues[WEB3_MQ_DID_TYPE.PHONE],
          4,
          3
        );
        hasPhone = true;
      }
      if (userInfo?.web3mqInfo?.didValues[WEB3_MQ_DID_TYPE.EMAIL]) {
        emailValue = getShortAddressByAddress(
          userInfo?.web3mqInfo?.didValues[WEB3_MQ_DID_TYPE.EMAIL],
          4,
          3
        );
        hasEmail = true;
      }
    }
    const config = [
      {
        icon: phoneIcon,
        type: "phone",
        title: "Phone",
        isShow: true,
        value: phoneValue,
        showAudit: store.isOwner() && !hasPhone,
        // showAudit: true,
        handleAudit: async () => {
          if (!store.client) {
            store.setShowModal(true);
            await openLoading("Loading");
            await loginWeb3MQ();
            await closeLoading();
            store.setShowModal(false);
          } else {
            setShowModal(true);
            setPageType(ACCOUNT_CONNECT_TYPE.PHONE);
          }
        },
      },
      {
        icon: emailIcon,
        type: "email",
        title: "Email",
        isShow: true,
        value: emailValue,
        showAudit: store.isOwner() && !hasEmail,
        handleAudit: async () => {
          if (!store.client) {
            await openLoading("Loading");
            await loginWeb3MQ();
            await closeLoading();
          } else {
            setShowModal(true);
            setPageType(ACCOUNT_CONNECT_TYPE.EMAIL);
          }
        },
      },
      {
        icon: "https://web3mq-docs.pages.dev/images/web3mq.logo.png",
        type: "web3mq",
        title: "Web3MQ",
        isShow: userInfo.web3mqInfo && userInfo.web3mqInfo.userid,
        value: getShortAddressByAddress(
          userInfo.web3mqInfo?.userid || "",
          10,
          5
        ),
        showAudit: false,
        handleAudit: async () => {},
      },
      {
        icon: profileENSIcon,
        isShow: !!userInfo.ens,
        type: "ens",
        title: "ENS",
        value: userInfo.ens ? userInfo.ens : "-",
        showAudit: store.isOwner() && !userInfo.ens,
        handleAudit: async () => {
          setShowModal(true);
          setPageType(ACCOUNT_CONNECT_TYPE.ENS);
        },
      },
      {
        icon: profileDotBitIcon,
        isShow: !!userInfo.bit,
        type: "dotbit",
        title: ".bit",
        value: userInfo.bit ? userInfo.bit : "-",
        showAudit: store.isOwner() && !userInfo.bit,
        handleAudit: async () => {
          setShowModal(true);
          setPageType(ACCOUNT_CONNECT_TYPE.DOTBIT);
        },
      },
      {
        icon: lensLogoIcon,
        isShow: !!userInfo.lens,
        type: "lens",
        title: "Lens",
        value: userInfo.lens ? userInfo.lens : "-",
        showAudit: store.isOwner() && !userInfo.lens,
        // showAudit: true,
        handleAudit: async () => {
          setShowModal(true);
          setPageType(ACCOUNT_CONNECT_TYPE.LENS);
        },
      },
    ];
    return (
      <div className={ss.walletsListContent}>
        {config.map((item, index) => {
          if (item.isShow) {
            return (
              <div className={ss.walletsList} key={`${index}_${item.type}`}>
                <img
                  style={{ width: "24px", height: "auto", borderRadius: "0" }}
                  src={item.icon}
                />
                <div className={ss.profileItemLabel}>
                  <div className={ss.itemLabelAccountTitle}>{item.title}</div>
                  <div className={ss.itemLabelText}>{item.value}</div>
                  {item.showAudit && (
                    <div
                      className={ss.connectAccountIcon}
                      onClick={item.handleAudit}
                    >
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={connectMoreAccountIcon}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }, [userInfo, store.loginUserInfo, store.client]);

  const RenderUserInfo = useCallback(() => {
    return (
      <div
        className={cx(ss.userInfoBox, {
          [ss.mobileUserInfoBox]: isMobile,
        })}
      >
        <div style={{ zIndex: 999 }} className={ss.avatarBox}>
          <img
            className={ss.avatarImg}
            src={userInfo.defaultUserAvatar}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = getUserAvatar(userInfo.address);
            }}
            alt=""
          />
        </div>
        <div className={ss.usernameBox}>
          <div className={ss.handle}>
            {userInfo.defaultUserName}
            {/*<img src={isBusinessUserIcon} alt="" />*/}
          </div>
          <div
            className={cx(ss.walletBox, {
              [ss.lensWalletBox]: isLensStyle,
            })}
          >
            {userInfo.address && (
              <div className={ss.wallet}>
                {getShortAddressByAddress(userInfo.address, 10)}
                <img
                  src={isLensStyle ? lensCopyIcon : copyIcon}
                  onClick={() => {
                    copy(userInfo.address);
                    present("Copied", 1000);
                  }}
                  className={ss.copyIcon}
                  alt=""
                />
              </div>
            )}
          </div>
          <div
            className={ss.followInfoBox}
            onClick={async () => {
              store.setShowModal(true);
              store.setPageType(PAGE_TYPE.SUBSCRIBERS);
              if (store.contacts.length <= 0 || store.followers.length <= 0) {
                await openLoading("Loading");
                await store.getContacts(userInfo.web3mqInfo?.userid || "");
                await store.getFollowers(userInfo.web3mqInfo?.userid || "");
                await closeLoading();
              }
            }}
          >
            <div className={ss.followNumBox}>
              <div
                className={ss.number}
                style={
                  userInfo.web3mqInfo?.stats?.total_following === 0
                    ? { color: "rgb(113, 113, 122)" }
                    : {}
                }
              >
                {userInfo.web3mqInfo?.stats?.total_following || 0}
              </div>
              <div className={ss.text}>Following</div>
            </div>
            <div className={ss.followNumBox}>
              <div
                className={ss.number}
                style={
                  userInfo.web3mqInfo?.stats?.total_followers === 0
                    ? { color: "rgb(113, 113, 122)" }
                    : {}
                }
              >
                {userInfo.web3mqInfo?.stats?.total_followers || 0}
              </div>
              <div className={ss.text}>Followers</div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [isMobile, userInfo]);

  const RenderSinglePost = useCallback(
    (props) => {
      const { post } = props;
      const { metadata, related_urls = [] } = post;
      return (
        <div className={ss.postItemBox}>
          <div className={ss.leftBox}>
            <img
              src={userInfo.defaultUserAvatar || userIcon}
              alt=""
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = getUserAvatar(userInfo.address);
              }}
            />
          </div>
          <div className={ss.centerBox}>
            <div className={ss.creatorInfoBox}>
              <div className={ss.upName}>{userInfo.defaultUserName}</div>
              <div className={ss.downName}>{userInfo.address}</div>
            </div>
            <div className={ss.title}>
              <b>{userInfo.defaultUserName + " "}</b>
              {Rss3TypeMap[post.type] + " on "}
              <b>{post.platform}</b>
            </div>
            <div className={ss.postContent}>{metadata.body}</div>
            {related_urls.length > 0 && (
              <div className={ss.detailButtons}>
                <div className={ss.detailTitle}>View Detail</div>
                <div className={ss.btnBox}>
                  {related_urls.map((relateUrl: string, index: number) => {
                    return (
                      <a key={index} href={relateUrl} target="_blank">
                        {getPlatformByUrl(relateUrl)}
                      </a>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          {/*<div className={ss.rightBox}>*/}
          {/*  {moment(post.createdAt).locale().}*/}
          {/*</div>*/}
        </div>
      );
    },
    [lensPosts]
  );

  const handleFollow = async () => {
    if (store.client) {
      await openLoading("Loading");
      const followRes = await store.client.user
        .followOperation({
          target_userid: userInfo.web3mqInfo?.userid || "",
          action: store.isFollowedByMe ? "cancel" : "follow",
        })
        .catch((e: any) => {
          console.log(e);
        });
      if (followRes && followRes.code === 0) {
        // store.setFollowStatus();
        await store.getUserInfo(userInfo.address);
      }
      await closeLoading();
    }
  };
  const handleSendMessage = async () => {
    const origin = window.location.origin + "/chat";
    window.open(origin);
    // store.setShowModal(true);
    // store.setPageType(PAGE_TYPE.BULK_MESSAGE);
    // if (store.client && store.userInfo && store.userInfo.web3mqInfo) {
    //   const userInfo = store.userInfo.web3mqInfo;
    //   store.setShowChat(true)
    //   // await store.client.channel.setActiveChannel()
    //   // await store.client.message.sendMessage("hello", userInfo.userid);
    // }
  };

  const RenderAuditBox = useCallback(() => {
    if (!store.loginUserInfo) {
      return null;
    }
    if (
      store.loginUserInfo &&
      store.loginUserInfo.address === userInfo.address
    ) {
      return null;
    }
    return (
      <div
        className={cx(ss.profileAuditButtonBox, {
          [ss.lensProfileAuditButtonBox]: isLensStyle,
        })}
      >
        <IonButton
          className={cx(ss.followButton, {
            [ss.unFollowButton]: store.isFollowedByMe,
          })}
          disabled={!store.userInfo || !store.userInfo.web3mqInfo}
          expand="block"
          onClick={handleFollow}
        >
          <img src={store.isFollowedByMe ? unFollowIcon : followIcon} alt="" />
          {store.isFollowedByMe ? "Unfollow" : "Follow"}
        </IonButton>
        <IonButton
          className={ss.messageButton}
          expand="block"
          onClick={handleSendMessage}
          disabled={
            !store.client || !store.userInfo || !store.userInfo.web3mqInfo
          }
        >
          <img
            src={isLensStyle ? lensSendMessageIcon : sendMessageIcon}
            alt=""
          />
          Messages
        </IonButton>
      </div>
    );
  }, [userInfo, store.loginUserInfo, store.isFollowedByMe]);

  return (
    <div className={ss.content}>
      <RenderUserInfo />
      <RenderAuditBox />
      <div
        className={cx(ss.tabs, {
          [ss.mobileTabs]: isMobile,
        })}
      >
        <div
          className={cx(ss.tabsItem, {
            [ss.tabsItemChecked]: segmentValue === "1",
            [ss.lensTabsItemChecked]: segmentValue === "1" && isLensStyle,
          })}
          onClick={() => {
            setSegmentValue("1");
          }}
        >
          Feed
        </div>
        <div
          className={cx(ss.tabsItem, {
            [ss.tabsItemChecked]: segmentValue === "2",
            [ss.lensTabsItemChecked]: segmentValue === "2" && isLensStyle,
          })}
          onClick={() => {
            setSegmentValue("2");
          }}
        >
          DIDs
        </div>
      </div>
      <div className={ss.segmentContentBox}>
        {segmentValue === "1" && (
          <>
            {lensPosts.length <= 0 || !needName ? (
              <div className={ss.emptyFeedBox}>
                <div className={ss.emptyEmoji}>🗒</div>
                <div className={ss.emptyTextBox}>
                  <p
                    className={ss.bigText}
                  >{`@${userInfo.defaultUserName} hasn't posted anything yet!`}</p>
                </div>
              </div>
            ) : (
              <div className={ss.postBox}>
                {lensPosts.map((item, index) => {
                  if (Rss3Platforms.includes(item.platform)) {
                    if (item.actions && item.actions[0]) {
                      const actionItem = item.actions[0];
                      const postItem = {
                        ...actionItem,
                        metadata: actionItem.metadata,
                      };
                      return <RenderSinglePost post={postItem} key={index} />;
                    }
                  }
                })}
                {lastCursor && (
                  <div onClick={getUserFeeds} className={ss.loadMoreBox}>
                    Load More ...
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {segmentValue === "2" && <RenderAccountsList />}
      </div>
      <IonModal
        isOpen={showModal}
        //@ts-ignore
        cssClass={ss.modal}
        onDidDismiss={() => {
          setShowModal(false);
        }}
      >
        <ConnectAccountModal type={pageType} closeModal={setShowModal} />
      </IonModal>
    </div>
  );
});
export default MemberProfileModal;
