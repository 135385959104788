import { IMGPROXY_URL, IPFS_GATEWAY } from "./index";
import Web3 from "web3";
//@ts-ignore
import { createInstance } from "dotbit";
import {ACCOUNT_CONNECT_TYPE} from "./enum";

//@ts-ignore
const web3 = new Web3(window.ethereum);

export function getShortAddressByAddress(
  address: string,
  num: number = 5,
  endNum = 4
) {
  let strLength = address.length;
  return (
    address.substring(0, num) +
    "..." +
    address.substring(strLength - endNum, strLength)
  );
}

export enum TOKEN_KEY {
  ACCESS = "access",
  LENS_ACCESS = "lens_access",
}

export function tokenMgr() {
  return {
    getToken: (type: TOKEN_KEY = TOKEN_KEY.ACCESS) => {
      let accessToken = localStorage.getItem(type);
      return accessToken ? accessToken : "";
    },
    setToken: (v: string, type: TOKEN_KEY = TOKEN_KEY.ACCESS) => {
      if (v) {
        localStorage.setItem(type, `Bearer ${v}`);
      } else {
        localStorage.setItem(type, "");
      }
    },
  };
}

export const imageProxy = (url: string, name?: string): string => {
  return name
    ? `${IMGPROXY_URL}/tr:n-${name},tr:di-placeholder.webp/${url}`
    : `${IMGPROXY_URL}/tr:di-placeholder.webp/${url}`;
};

export const getIPFSLink = (hash: string): string => {
  const gateway = IPFS_GATEWAY;

  return hash
    .replace(/^Qm[1-9A-Za-z]{44}/gm, `${gateway}${hash}`)
    .replace("https://ipfs.io/ipfs/", gateway)
    .replace("ipfs://", gateway);
};

export const getAddressByDid = async (
  did: string,
  didType: ACCOUNT_CONNECT_TYPE
) => {
  if (didType === ACCOUNT_CONNECT_TYPE.DOTBIT) {
    const dotbit = createInstance();
    try {
      const data = await dotbit.records(did);
      let ethAddress =
        data && data.find((item: any) => item.key === "address.eth");
      return ethAddress?.value || "";
    } catch (e) {
      console.log(e, "e");
      return null;
    }
  }

  if (didType === ACCOUNT_CONNECT_TYPE.ENS) {
    try {
      const address = await web3.eth.ens.getAddress(did);
      return address;
    } catch (e) {
      return null;
    }
  }
  return null;
};

export const getPlatformByUrl = (url: string) => {
  try {
    const urlObj = new URL(url);
    return urlObj.host.split("www.")[0]
      ? urlObj.host.split("www.")[0]
      : urlObj.host.split("www.")[1];
  } catch (e) {
    console.log(e);
    return "";
  }
};

export const getUserAvatar = (address: string) =>  {
  return `https://cdn.stamp.fyi/avatar/${address}?s=300`
}

