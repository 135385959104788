import React from "react";
import { observer } from "mobx-react-lite";
import { IonButton } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useStore } from "../../services/mobx/service";

const ProfileEmptyPage: React.FC = observer(() => {
  const history = useHistory();
  const store = useStore();
  return (
    <div>
      查无此人！！！！
      <IonButton
        onClick={() => {
          if (store.loginUserInfo) {
            history.push(`/home/${store.loginUserInfo.address}`);
          } else {
              console.log('不正常1');
            // @ts-ignore
            history.push(`/home/${window.ethereum.selectedAddress}`);
          }
        }}
      >
        Go to home
      </IonButton>
    </div>
  );
});

export default ProfileEmptyPage;
