export enum PAGE_TYPE {
  PROFILE = "profile",
  SUBSCRIBERS = "subscribers",
  SETTING = "setting",
  BULK_MESSAGE = "bulk_message",
}

export enum PERMISSION_ACTION {
  PUBLIC = "public",
  RECEIVE = "receive",
}

export enum PLATFORM_ENUM {
  TWITTER = "twitter",
  DISCORD = "discord",
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  OPENSEA = "opensea",
}

export enum WEB3_MQ_DID_TYPE {
  PHONE = "phone",
  EMAIL = "email",
  LENS = "lens.xyz",
  DOTBIT = "dotbit",
  ENS = "ens",
}

export enum SETTING_VALUE_ENUM {
  METHOD_EMAIL = "method_email",
  METHOD_PHONE = "method_phone",
  METHOD_WEB = "method_web",
  // METHOD_CHAT = "method_chat",
  AUTH_ANYONE = "auth_anyone",
  AUTH_FRIENDS = "auth_friends",
  AUTH_VERIFIED = "auth_verified",
}

export const Rss3Platforms = [
  "Mirror",
  "Lens",
  "Lenster",
  "Crossbell",
  "xLog",
  "Farcaster",
  "EIP-1577",
  "IQ.Wiki",
];

export const Rss3TypeMap: Record<any, any> = {
  post: "posted a note",
  comment: "made a comment",
  profile: "created a profile",
};

export enum ACCOUNT_CONNECT_TYPE {
  PHONE = "phone",
  EMAIL = "email",
  LENS = "lens",
  ENS = "ens",
  DOTBIT = "dotbit",
}

export  const RSS3_USER_DID_PROFILE_PLATFORM = {
  ens: 'ENS Registrar',
  lens: 'Lens',
  csb: 'Crossbell'
}
