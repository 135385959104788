import React, { useEffect, useMemo, useState } from "react";
import ss from "./index.module.scss";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { closeOutline } from "ionicons/icons";

import successIcon from "../../assert/svg/bindAccountSuccessIcon.svg";
import { useStore } from "../../services/mobx/service";
import { TextFieldTypes } from "@ionic/core";
import { PROVIDER_ID_CONFIG } from "../../constant";
import { ACCOUNT_CONNECT_TYPE, WEB3_MQ_DID_TYPE } from "../../constant/enum";
import { getProfiles } from "../../lens/api";
import userIcon from "../../assert/svg/user.svg";
import { pageConfig } from "./config";
import { getAddressByDid } from "../../constant/utils";

interface IAppProps {
  type: ACCOUNT_CONNECT_TYPE;
  closeModal: any;
}

const ConnectAccountModal: React.FC<IAppProps> = observer((props) => {
  const { type, closeModal } = props;
  const store = useStore();
  const [openToast] = useIonToast();
  const [openLoading, closeLoading] = useIonLoading();
  const [openAlert, closeAlert] = useIonAlert();
  const [step, setStep] = useState<number>(1);
  const [inputValue, setInputValue] = useState(
    type === ACCOUNT_CONNECT_TYPE.PHONE ? "+1" : ""
  );
  const [codeValue, setCodeValue] = useState("");
  const [lensAccounts, setLensAccounts] = useState<any[]>([]);

  const handleConfirmBindLens = async (item: any) => {
    if (store.loginUserInfo && store.client) {
      const res = await store.client.user
        .userBindDid({
          did_type: "lens.xyz",
          did_value: item.handle,
          provider_id: PROVIDER_ID_CONFIG[ACCOUNT_CONNECT_TYPE.LENS],
        })
        .catch((e) => {
          openToast("Bind Failed", 2000);
        });
    } else {
      await openToast("Bind Failed", 2000);
    }
  };

  const handleItemLensClick = async (item: any) => {
    await openAlert({
      header: `Would you want bind ${item.handle} to your Web3MQ?`,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: [ss.alertCancelBtn],
          handler: () => {
            closeAlert();
          },
        },
        {
          text: "confirm",
          role: "confirm",
          cssClass: [ss.alertConfirmBtn],
          handler: async () => {
            await handleConfirmBindLens(item);
          },
        },
      ],
    });
  };

  const getLensAccountList = async () => {
    await openLoading("Loading");
    if (store.loginUserInfo) {
      const address = store.loginUserInfo.address;
      const profiles = await getProfiles(address);
      if (profiles && profiles.items && profiles.items.length > 0) {
        setLensAccounts(profiles.items);
      }
    }
    closeLoading();
  };

  const sendAccountConnectCode = async () => {
    if (store.client) {
      if (
        [ACCOUNT_CONNECT_TYPE.ENS, ACCOUNT_CONNECT_TYPE.DOTBIT].includes(type)
      ) {
        await openLoading("Loading");
        const address = await getAddressByDid(inputValue, type);
        if (address) {
          if (
            address.toLowerCase() ===
            store.loginUserInfo?.address?.toLowerCase()
          ) {
            const bindRes = await store.client.user
              .userBindDid({
                did_type: type,
                did_value: inputValue,
                provider_id: PROVIDER_ID_CONFIG[type],
              })
              .catch((e) => {
                console.log(e, "e");
              });
            console.log(bindRes, "bindRes");
            if (bindRes && bindRes.code === 0) {
              setStep(3);
            } else {
              await openToast("Bind Failed", 2000);
            }
          } else {
            await openToast("It's not your account", 2000);
          }
        } else {
          await openToast("Can not find this address", 2000);
        }
        await closeLoading();
        return null;
      }

      await openLoading("Loading");
      const res = await store.client.user.userBindDid({
        provider_id:
          type === ACCOUNT_CONNECT_TYPE.PHONE
            ? PROVIDER_ID_CONFIG[ACCOUNT_CONNECT_TYPE.PHONE]
            : PROVIDER_ID_CONFIG[ACCOUNT_CONNECT_TYPE.EMAIL],
        did_type:
          type === ACCOUNT_CONNECT_TYPE.EMAIL
            ? WEB3_MQ_DID_TYPE.EMAIL
            : WEB3_MQ_DID_TYPE.PHONE,
        did_value: inputValue,
      });
      await closeLoading();
      if (res.code === 0) {
        await openToast(res.msg, 3000);
        if (type === ACCOUNT_CONNECT_TYPE.EMAIL) {
          setStep(3);
        } else {
          if (res.data && res.data.verification_key) {
            localStorage.setItem(
              "sms_verification_key",
              res.data.verification_key
            );
          }
          setStep(2);
        }
      }
    }
  };

  const verifyAccountConnect = async () => {
    if (store.client) {
      const res = await store.client.user.userBindDid({
        provider_id: PROVIDER_ID_CONFIG[ACCOUNT_CONNECT_TYPE.PHONE],
        did_type: WEB3_MQ_DID_TYPE.PHONE,
        did_value: inputValue,
        did_content: `${localStorage.getItem(
          "sms_verification_key"
        )}@${codeValue}`,
        did_action: "verification",
      });
    }
    setStep(3);
  };

  const handleConnectSuccess = async () => {
    if (store.client) {
      await openLoading("Loading");
      const dids = await store.client.user.getUserBindDids();
      await closeLoading();
      if (dids.length > 0) {
        const didValues  = await store.setUserDid(dids);
        await store.setLoginUserInfoDids(didValues)
        closeModal();
      } else {
        closeModal();
      }
    }
  };

  const isDisable = useMemo(() => {
    if (ACCOUNT_CONNECT_TYPE.EMAIL === type) {
      const reg =
        /^[a-zA-Z0-9_.-\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
          inputValue
        );
      return !reg;
    }
    if (ACCOUNT_CONNECT_TYPE.PHONE === type) {
      return inputValue.length <= 5;
    }
    return !inputValue;
  }, [inputValue]);

  useEffect(() => {
    if (type === ACCOUNT_CONNECT_TYPE.LENS) {
      getLensAccountList().then();
    }
  }, []);

  return (
    <IonPage className={ss.page}>
      <IonHeader className={ss.header}>
        <IonToolbar>
          <IonTitle>{pageConfig[type].headerTitle}</IonTitle>
          <IonButtons slot="end" className="ion-close-icon done-button">
            <IonButton onClick={closeModal}>
              <IonIcon
                style={{ color: "#000" }}
                slot="icon-only"
                icon={closeOutline}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={ss.content}>
        {step === 1 && (
          <div className={ss.contentBox}>
            <div className={ss.contentTitle}>
              {pageConfig[type].contentTitle}
            </div>
            {type === ACCOUNT_CONNECT_TYPE.LENS ? (
              <div className={ss.inputBox}>
                {lensAccounts.map((item, index) => (
                  <div
                    key={index}
                    className={ss.lensItem}
                    onClick={async () => {
                      await handleItemLensClick(item);
                    }}
                  >
                    <img className={ss.avatar} src={userIcon} alt="" />
                    <div className={ss.name}>
                      {item.handle ? item.handle : item.name}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={ss.inputBox}>
                <div className={ss.inputHeader}>{type}</div>
                <IonInput
                  className={ss.input}
                  value={inputValue}
                  onIonChange={(e) => {
                    let value = e.detail.value!;
                    setInputValue(value);
                  }}
                  placeholder={pageConfig[type].placeholder}
                  type={pageConfig[type].inputType as TextFieldTypes}
                  onIonBlur={() => {}}
                />
              </div>
            )}
          </div>
        )}
        {step === 2 && (
          <div className={ss.contentBox}>
            <div className={ss.contentTitle}>
              {pageConfig[type].stepTwoContentTitle}
            </div>
            <div className={ss.inputBox}>
              <div className={ss.inputHeader}>Code</div>
              <IonInput
                className={ss.input}
                value={codeValue}
                onIonChange={(e) => {
                  let value = e.detail.value!;
                  setCodeValue(value);
                }}
                placeholder="000-000"
              />
            </div>
          </div>
        )}
        {step === 3 && (
          <div className={ss.contentBox}>
            <div className={ss.successBox}>
              <img src={successIcon} alt="" />
              <div className={ss.successTitle}>
                {pageConfig[type].successTitle}
              </div>
              <div className={ss.successSubTitle}>
                {pageConfig[type].successSubTitle}
              </div>
            </div>
          </div>
        )}
      </IonContent>
      <IonFooter className={ss.footer}>
        {step === 1 && type !== ACCOUNT_CONNECT_TYPE.LENS && (
          <IonButton
            className={ss.actionButton}
            expand="block"
            disabled={isDisable}
            onClick={sendAccountConnectCode}
          >
            Next
          </IonButton>
        )}
        {step === 2 && (
          <IonButton
            className={ss.actionButton}
            expand="block"
            disabled={!codeValue}
            onClick={verifyAccountConnect}
          >
            Verify
          </IonButton>
        )}
        {step === 3 && (
          <IonButton
            className={ss.actionButton}
            expand="block"
            onClick={handleConnectSuccess}
          >
            Great
          </IonButton>
        )}
      </IonFooter>
    </IonPage>
  );
});
export default ConnectAccountModal;
