import React, { useCallback, useEffect, useState } from "react";
import ss from "./index.module.scss";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonTextarea,
  IonToggle,
  IonToolbar, useIonLoading,
  useIonToast,
} from "@ionic/react";
import { useStore } from "src/services/mobx/service";
import { observer } from "mobx-react-lite";
import cx from "classnames";
import userIcon from "../../assert/svg/user.svg";
import {
  PAGE_TYPE,
  PERMISSION_ACTION,
  WEB3_MQ_DID_TYPE,
} from "../../constant/enum";
import { useHistory } from "react-router-dom";
import { closeOutline } from "ionicons/icons";
import { getShortAddressByAddress } from "../../constant/utils";

const pushMessageRadioTrue =
  require("../../assert/svg/pushMessageRadioTrue.svg").default;

enum TAB_TYPE {
  CONTACTS = "contacts",
  FOLLOWERS = "followers",
  RECEIVE_AUTH = "receive_auth",
  PROFILE_AUTH = "profile_auth",
}

const tabConfig = {
  [PAGE_TYPE.SUBSCRIBERS]: {
    title: "Follow",
    value: PAGE_TYPE.SUBSCRIBERS,
  },
  [PAGE_TYPE.SETTING]: {
    title: "Setting",
    value: PAGE_TYPE.SETTING,
  },
  [PAGE_TYPE.PROFILE]: {
    title: "Profile",
    value: PAGE_TYPE.PROFILE,
  },
  [PAGE_TYPE.BULK_MESSAGE]: {
    title: "Bulk Message",
    value: PAGE_TYPE.BULK_MESSAGE,
  },
};

const pageTabConfig = {
  [PAGE_TYPE.SUBSCRIBERS]: [
    {
      title: "Followers",
      value: TAB_TYPE.FOLLOWERS,
    },
    {
      title: "Following",
      value: TAB_TYPE.CONTACTS,
    },
  ],
  [PAGE_TYPE.PROFILE]: [],
  [PAGE_TYPE.SETTING]: [
    // {
    //   title: "Profile",
    //   value: TAB_TYPE.PROFILE_AUTH,
    // },
    // {
    //   title: "Configure",
    //   value: TAB_TYPE.RECEIVE_AUTH,
    // },
  ],
  [PAGE_TYPE.BULK_MESSAGE]: [],
};

interface IAppProps {
  isLensStyle?: boolean;
}

const ProfileModal: React.FC<IAppProps> = observer((props) => {
  const store = useStore();
  const history = useHistory();
  const [openToast] = useIonToast();
  const [openLoading, closeLoading] = useIonLoading();
  const [tabType, setTabType] = useState<TAB_TYPE>();
  const { isLensStyle = false } = props;
  const [hashSettingValue, setHashSettingValue] = useState("");
  const [messageTextarea, setMessageTextarea] = useState("");

  useEffect(() => {
    if (store.pageType === PAGE_TYPE.SUBSCRIBERS) {
      setTabType(TAB_TYPE.FOLLOWERS);
    }
    if (store.pageType === PAGE_TYPE.SETTING) {
      setTabType(TAB_TYPE.RECEIVE_AUTH);
    }
  }, []);

  useEffect(() => {
    if (
      tabType &&
      [TAB_TYPE.PROFILE_AUTH, TAB_TYPE.RECEIVE_AUTH].includes(tabType)
    ) {
      initSettingValue();
    }
  }, [tabType]);

  const handleSubmit = async () => {
    if (store.client) {
      const authConfig = hashSettingValue ? JSON.parse(hashSettingValue) : [];
      const params: Record<any, any> = {};
      authConfig.forEach((item: any) => {
        params[item.key] = {
          type: item.type,
          value: item.value,
        };
      });
      const res = await store.client.user.updateUserPermissions({
        permissions: params,
      });
      if (res) {
        await openToast("Update Success", 2000);
        store.setShowModal(false);
        store.setPageType(PAGE_TYPE.PROFILE);
      } else {
        await openToast("Update Failed", 2000);
      }
    } else {
      await openToast("Token expired", 2000);
    }
  };

  const handleToggleChange = (key: string, value: boolean) => {
    const authConfig = hashSettingValue ? JSON.parse(hashSettingValue) : [];
    console.log(authConfig, "authConfig");
    const newConfig = authConfig.map((item: any) => {
      if (item.key === key) {
        item.value = value;
      }
      return item;
    });
    setHashSettingValue(JSON.stringify(newConfig));
  };

  const initSettingValue = () => {
    if (!store.loginUserInfo) {
      return null;
    }
    const { permissions } = store.loginUserInfo;
    const didValues = store.loginUserInfo.web3mqInfo?.didValues || {}
    let prePermissions: Record<string, any> = {
      "profile:public": true,
    };
    let authConfig: any[] = [];
    if (Object.keys(didValues).length > 0) {
      for (const didKey in didValues) {
        prePermissions[`${didKey}:${PERMISSION_ACTION.PUBLIC}`] = {
          type: "bool",
          value: false,
        };
        if (![`${WEB3_MQ_DID_TYPE.LENS}`].includes(didKey)) {
          prePermissions[`${didKey}:${PERMISSION_ACTION.RECEIVE}`] = {
            type: "bool",
            value: false,
          };
        }
      }
    }
    for (const prePermissionsKey in prePermissions) {
      if (permissions && permissions[prePermissionsKey]) {
        prePermissions[prePermissionsKey] = permissions[prePermissionsKey];
      }
      const keyType = prePermissionsKey.split(":")[1];
      if (
        keyType === PERMISSION_ACTION.RECEIVE &&
        tabType === TAB_TYPE.RECEIVE_AUTH
      ) {
        authConfig.push({
          ...prePermissions[prePermissionsKey],
          key: prePermissionsKey,
        });
      }
      if (
        keyType === PERMISSION_ACTION.PUBLIC &&
        tabType === TAB_TYPE.PROFILE_AUTH
      ) {
        authConfig.push({
          ...prePermissions[prePermissionsKey],
          key: prePermissionsKey,
        });
      }
    }
    setHashSettingValue(JSON.stringify(authConfig));
  };

  const submitSendData = async () => {
    await openLoading('Loading')
    console.log("submit push message: ", messageTextarea);
    if (store.loginUserInfo && store.client) {
      const pushRes = await store.client.user.publishNotificationToFollowers({
        title: "Push Message",
        content: messageTextarea,
      });
      console.log(pushRes, "pushRes");
      setMessageTextarea("");
      store.setShowModal(false);
      store.setPageType(PAGE_TYPE.PROFILE);
    } else {
      openToast("Something was wrong");
    }
    await closeLoading()
  };

  const RenderSubscribers = useCallback(() => {
    const users =
      tabType === TAB_TYPE.FOLLOWERS ? store.followers : store.contacts;
    return (
      <IonContent className={ss.contactsList}>
        {users.length > 0 &&
          users.map((user: any, index) => (
            <div
              key={index}
              className={ss.contactItem}
              onClick={async () => {
                if (user.wallet_address) {
                  history.push(`/home/${user.wallet_address}`);
                  // store.emptyUserInfo();
                  store.setShowModal(false);
                  store.setPageType(PAGE_TYPE.PROFILE);
                }
              }}
            >
              <img
                className={ss.avatar}
                src={user.avatar_url || userIcon}
                alt=""
              />
              <div className={ss.name}>
                {user.nickname
                  ? user.nickname
                  : getShortAddressByAddress(user.userid, 20, 15)}
              </div>
            </div>
          ))}
      </IonContent>
    );
  }, [tabType, store.followers, store.contacts]);

  const RenderSetting = useCallback(() => {
    const authConfig = hashSettingValue ? JSON.parse(hashSettingValue) : null;
    return (
      <div className={ss.profileSetting}>
        {tabType === TAB_TYPE.PROFILE_AUTH && (
          <div className={ss.settingContent}>
            <div className={ss.settingTitle}>
              Whether the following information is publicly displayed
            </div>
            <div className={ss.list}>
              {authConfig &&
                authConfig.map((item: any, index: any) => {
                  return (
                    <div className={ss.item} key={index}>
                      <div className={ss.text}>{item.key.split(":")[0]}</div>
                      <IonToggle
                        checked={item.value}
                        onIonChange={(e) => {
                          handleToggleChange(item.key, e.detail.checked);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        {tabType === TAB_TYPE.RECEIVE_AUTH && (
          <div className={ss.settingContent}>
            <div className={ss.settingTitle}>
              Whether to receive messages from
            </div>
            <div className={ss.list}>
              {authConfig &&
                authConfig.map((item: any, index: number) => {
                  return (
                    <div className={ss.item} key={index}>
                      <div className={ss.text}>{item.key.split(":")[0]}</div>
                      <IonToggle
                        checked={item.value}
                        onIonChange={(e) => {
                          handleToggleChange(item.key, e.detail.checked);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        <div className={ss.settingFooter}>
          <div className={ss.submitButton} onClick={handleSubmit}>
            + Submit
          </div>
        </div>
      </div>
    );
  }, [tabType, handleToggleChange, handleSubmit, hashSettingValue]);

  return (
    <div className={ss.body}>
      <IonHeader className={ss.header}>
        <IonToolbar>
          <div className={ss.headerTitle}>
            {tabConfig[store.pageType].title}
          </div>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                store.setShowModal(false);
                store.setPageType(PAGE_TYPE.PROFILE);
              }}
            >
              <IonIcon
                style={{ color: "#000" }}
                slot="icon-only"
                icon={closeOutline}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <div className={ss.content}>
        <div className={ss.subscribersBox}>
          {pageTabConfig[store.pageType].length > 0 && (
            <div
              className={cx(ss.tabs, {
                [ss.lensStyleTabs]: isLensStyle,
              })}
            >
              {pageTabConfig[store.pageType].map((item, index) => {
                return (
                  <div
                    key={index}
                    className={cx(ss.tabsItem, {
                      [ss.tabsItemChecked]: tabType === item.value,
                    })}
                    onClick={() => {
                      setTabType(item.value);
                    }}
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
          )}

          <div className={ss.pageCommonContent}>
            {store.pageType === PAGE_TYPE.SUBSCRIBERS && <RenderSubscribers />}
            {store.pageType === PAGE_TYPE.SETTING && <RenderSetting />}
            {store.pageType === PAGE_TYPE.BULK_MESSAGE && (
              <div className={ss.bulkMessageBox}>
                <div className={ss.messageTitle}>
                  Push a notification to your followers
                </div>
                <div className={ss.pushToList}>
                  <div className={ss.title}>Send to</div>
                  <div className={ss.pushMessageTypeRadio}>
                    <img src={pushMessageRadioTrue} alt="" />
                    <div>All Followers</div>
                  </div>
                </div>
                <div className={ss.pushMessageContent}>
                  <div className={ss.title}>Message</div>
                  <IonTextarea
                    auto-grow
                    rows={4}
                    maxlength={500}
                    className={ss.textareaValue}
                    value={messageTextarea}
                    placeholder="Write something..."
                    onIonChange={(e) => setMessageTextarea(e.detail.value!)}
                  />
                </div>
                <div className={ss.pushMessageType}>
                  <div className={ss.title}>Send via</div>
                  <div className={ss.pushMessageTypeBox}>
                    <div className={ss.pushMessageTypeRadio}>
                      <img src={pushMessageRadioTrue} alt="" />
                      <div>Notification</div>
                    </div>
                  </div>
                </div>
                <div className={ss.sendBtnBox}>
                  <div className={ss.sendBtn} onClick={submitSendData}>
                    + Send
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
export default ProfileModal;
